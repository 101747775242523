import { List as ImmutableList } from 'immutable';
import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';

import { AddCellDirection, Layer, MAX_CELLS_COUNT, SplitCellDirection } from 'const';
import { Priority, useClickOutside, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { LayeredRelationsMap } from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import { isSelectableDocument } from 'utils/documents/isSelectableDocument';
import { isCallToAction, isImage, isLayoutRendition, isTextComponent } from 'utils/entityType';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getMaxRowHeight } from 'utils/relations/getMaxHeight';
import { getMinColumnWidth } from 'utils/relations/getMinColumnWidth';
import { getMinRowHeight } from 'utils/relations/getMinRowHeight';
import { isRegularRelation } from 'utils/relations/isRegularRelation';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { recalculateRowsAndNeighborsHeight, recalculateRowsHeight } from 'utils/rowsHeight';
import { getParent } from 'utils/rowsHeight/getParent';
import { createUndoRedoClickOutsideHandler } from './factories/createUndoRedoClickOutsideHandler';
import { ArtboardCellProps } from './models';
import { calculateDeltaAndRelations } from './utils';

export const useArtboardCell = (props: ArtboardCellProps) => {
  const {
    activeLayer,
    addCell,
    addSelectedArtboardComponent,
    canDrop,
    cellsCount,
    columnRelationId,
    columnsCount,
    copyCellContent,
    deleteCell,
    height,
    isOpenToolbar,
    isOver,
    isResizingRow,
    isReusableLayout,
    layout,
    layoutId,
    layoutRelations,
    notEditable,
    parentRelationId,
    positionWithinColumn,
    relation,
    removeSelectedArtboardComponent,
    selectionState,
    setRowHeightSilently,
    splitCell,
    toggleExpandedMode,
    toggleLayoutRelations,
    updateCellsHeight,
    updateRelationsSilently,
    width,
    images,
    callToActions,
    reusableLayouts,
    textComponents,
  } = props;
  const relationId = relation.get('id');

  /**
   * DCC-8592
   * Sometimes there documents are no translated document for CTA and this is the reason why we decided to use original document for CTA.
   * It's a temporary solution. We must understand how to deal with missing document links.
   */
  const documentId = relation.getIn(['documentId', activeLayer])
    ||
    (
      isCallToAction(relation.get('entityType')) ?
        relation.getIn(['documentId', Layer.ORIGINAL]) :
        undefined
    );

  const document = documentId &&
    (
      images.get(documentId)
      || callToActions.get(documentId)
      || textComponents.get(documentId)
      || reusableLayouts.get(documentId)
    );

  const canDropHere = canDrop && isOver;
  const layoutCellsCount = layoutRelations.filter(isRegularRelation).size;
  const deletingDisabled = layoutCellsCount <= 1 && columnsCount <= 1 && !documentId;
  const copyingEnabled = !isReusableLayout && document && (isCallToAction(document) || isTextComponent(document));
  const updateCellHeight = (value: number): void => {
    updateCellsHeight(relation.get('id'), value);
  };

  const parentRelation = layoutRelations.get(parentRelationId);
  const isParentRelationRow = isRowRelation(parentRelation);

  const relationToResizeHeight = isParentRelationRow ? parentRelation : relation;
  const relationToResizeWidth = isParentRelationRow ? relation : parentRelation;

  const { parent, position } = useMemo(() => {
    return getParent(relationToResizeWidth.get('id'), layoutRelations);
  }, [layoutRelations, relationToResizeWidth]);

  const defaultColumnsWidth = useMemo(() => ImmutableList([width]), [width]);

  const columnsWidth = useMemo(() => {
    return isRowRelation(parent) ? parent.getIn(['styles', 'columnsWidth']) : defaultColumnsWidth;
  }, [defaultColumnsWidth, parent]);

  const columnPosition = useMemo(() => {
    return isRowRelation(parent) ? position : 0;
  }, [position, parent]);


  const minHeight = getMinRowHeight(relationToResizeHeight.get('id'), layoutRelations, activeLayer);
  const maxHeight = getMaxRowHeight(relationToResizeHeight.get('id'), layoutRelations, activeLayer);
  const minWidth = getMinColumnWidth(relationToResizeWidth.get('id'), layoutRelations);

  const isImageDocument = document ? isImage(document) : false;
  const docImageWidth = document && (isImageDocument ? document.getIn(['_internalInfo', 'width']) : document.getIn(['_thumbnailWidth']));
  const docImageHeight = document && (isImageDocument ? document.getIn(['_internalInfo', 'height']) : document.getIn(['_thumbnailHeight']));
  const documentImageSrc = document && (isImageDocument ? document.getIn(['_internalInfo', 'source']) : document.getIn(['_thumbnailUrl']));
  // Some relations doesn't have a translated layer and therefore component fails. This is a data issue
  const styles = relation.getIn(['styles', activeLayer]) || relation.getIn(['styles', Layer.ORIGINAL]);
  const autoFitContentStyle = styles.get('isAutoFitContent');
  const [isAutoFitContent, setIsAutoFitContent] = React.useState(autoFitContentStyle);
  const {
    container,
    on: editMode,
    toggleOn: toggleEditModeOn,
    toggleOff: toggleEditModeOff,
  } = useClickOutsideWithToggle<HTMLDivElement>(Priority.TOOLBAR, {
    closeDropdownCallback: () => {
      return toggleExpandedMode(false);
    },
    createCustomClickHandler: createUndoRedoClickOutsideHandler,
  });

  // To be able to manipulate autofit from the upper level (row, relation)
  React.useEffect(
    () => {
      if (isAutoFitContent !== autoFitContentStyle) {
        setIsAutoFitContent(autoFitContentStyle);
      }
    },
    [autoFitContentStyle],
  );

  const toggleAutoFitContent = useCallback((): void => {
    setIsAutoFitContent(!isAutoFitContent);
    updateCellHeight(container.current.offsetHeight);
  }, [setIsAutoFitContent, isAutoFitContent, updateCellHeight]);

  const onImageLoad = (): void => {
    updateCellHeight(container.current.offsetHeight);

    if (!isImage(relation) && !isLayoutRendition(relation)) {
      return;
    }

    const activeLayerStyles = relation.getIn(['styles', activeLayer]);

    const scale = activeLayerStyles.get('scale');
    const extraWidth = getExtraWidth(activeLayerStyles);

    const imageWidth = _.max([_.round((width - extraWidth) * scale), 1]);
    const imageHeight = _.max([_.round(imageWidth * docImageHeight / docImageWidth), 1]);
    const imageContentHeight = imageHeight + getExtraHeight(activeLayerStyles);
    const newVerifiedHeight = _.clamp(imageContentHeight, minHeight, Infinity);
    const hightStyle = relation.getIn(['styles', activeLayer, 'height']);
    if (hightStyle === 1) {
      const heightRelation = relation.setIn(['styles', activeLayer, 'height'], imageHeight);
      const updatedLayoutRelations = layoutRelations.set(relationId, heightRelation);

      const relationsForUpdate = calculateDeltaAndRelations(
        relationId,
        updatedLayoutRelations,
        height - newVerifiedHeight,
        activeLayer,
        layout,
      ) || updatedLayoutRelations;

      updateRelationsSilently(relationsForUpdate, layoutId);
    }

    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(relation.get('id'), imageContentHeight);

    const isSizeAdjusted = activeLayerStyles.get('isSizeAdjusted');
    if (isSizeAdjusted) {
      return;
    }

    const shouldUpdateCellHeight = isAutoFitContent && !isResizingRow
      ? newVerifiedHeight !== height
      : newVerifiedHeight > height;

    if (!height || !shouldUpdateCellHeight) {
      return;
    }

    const adjustedRelation = relation.setIn(['styles', activeLayer, 'isSizeAdjusted'], true);
    const updatedLayoutRelations = layoutRelations.set(relationId, adjustedRelation);

    const relationsForUpdate = calculateDeltaAndRelations(
      relationId,
      updatedLayoutRelations,
      height - newVerifiedHeight,
      activeLayer,
      layout,
    ) || updatedLayoutRelations;

    updateRelationsSilently(relationsForUpdate, layoutId);
  };

  const onDeleteCell = (): void => {
    /*
      `toggleRowAndNeighborsHeight()` must go before `deleteCell()`,
      otherwise Image is not gonna be deleted
    */
    const minHeight = getMinRowHeight(relation.get('id'), layoutRelations, activeLayer, isAutoFitContent, height);
    toggleRowAndNeighborsHeight(minHeight, layoutRelations);

    deleteCell(layoutId, relation.get('id'));
  };
  const onCopyContent = (): void => {
    copyCellContent(layoutId, relation.get('id'));
  };
  const onAddCell = (direction: AddCellDirection): void => {
    addCell(direction, parentRelationId, relation.get('id'));
  };
  const onSplitCell = (direction: SplitCellDirection): void => {
    splitCell(direction, layoutId, parentRelationId, relation.get('id'));
  };

  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const hasFontsLoadBeenProcessed = React.useRef<boolean>(false);
  const hasHeightBeenSet = React.useRef<boolean>(false);

  // set the initial height
  React.useEffect(
    () => {
      if (!container.current || isParentRelationRow) {
        return;
      }

      if (height) {
        hasHeightBeenSet.current = false;

        return;
      }

      if (hasHeightBeenSet.current) {
        return;
      }

      const { clientHeight: newHeight } = container.current;
      setRowHeightSilently(columnRelationId, positionWithinColumn, newHeight);
      hasHeightBeenSet.current = true;
    },
    [isParentRelationRow],
  );

  // reset the flags
  React.useEffect(
    () => {
      hasFontsLoadBeenProcessed.current = false;
      hasHeightBeenSet.current = false;
      setFontsLoaded(false);
      let mounted = true;

      void window.document.fonts.ready.then(
        () => mounted && setFontsLoaded(true),
      );

      return (): void => {
        mounted = false;
      };
    },
    [relation.getIn(['documentId', activeLayer])],
  );

  // update the height in case fonts are loaded
  React.useEffect(
    () => {
      if (!container.current) {
        return;
      }

      const { clientHeight: newHeight } = container.current;

      if (!height || height === newHeight) {
        return;
      }

      if (fontsLoaded && !hasFontsLoadBeenProcessed.current) {
        const newVerifiedHeight = _.clamp(newHeight, minHeight, Infinity);
        updateRelationsSilently(
          recalculateRowsAndNeighborsHeight(relation.get('id'), layoutRelations, height - newVerifiedHeight),
          layoutId,
        );
        hasFontsLoadBeenProcessed.current = true;
      }
    },
    [fontsLoaded],
  );

  const resizeObserver = React.useRef(new ResizeObserver((entries) => {
    updateCellHeight(entries[0].contentBoxSize[0].blockSize);
  }));

  React.useEffect(
    () => {
      resizeObserver.current.observe(container.current);
      updateCellHeight(container.current.offsetHeight);

      return (): void => resizeObserver.current.disconnect();
    },
    [],
  );

  const enterEditMode = React.useCallback(
    () => {
      if (!notEditable && !isOpenToolbar) {
        toggleEditModeOn();
        toggleExpandedMode(true);
      }
    },
    [notEditable, isOpenToolbar, relation, document],
  );

  const toggleColumnWidth = useCallback((_width: number): number => {
    const editableNeighborPosition = columnsWidth.size === columnPosition + 1 ? columnPosition - 1 : columnPosition + 1;
    const editableNeighborWidth = columnsWidth.get(editableNeighborPosition);
    const columnWidth = columnsWidth.get(columnPosition);

    const maxWidth = columnWidth + editableNeighborWidth - minWidth;

    const newColumnWidth = _.clamp(
      _width,
      minWidth,
      maxWidth,
    );

    const newEditableNeighborWidth = editableNeighborWidth - (newColumnWidth - columnWidth);
    const newColumnsWidth = columnsWidth.set(columnPosition, newColumnWidth).set(editableNeighborPosition, newEditableNeighborWidth);
    const newRowRelation = parent.setIn(['styles', 'columnsWidth'], newColumnsWidth);
    toggleLayoutRelations(layoutRelations.set(newRowRelation.get('id'), newRowRelation));

    return newColumnWidth;
  }, [columnsWidth, columnPosition, toggleLayoutRelations, minWidth, layoutRelations, parent]);

  const toggleRowHeight = useCallback((newHeight: number): number => {
    const newVerifiedHeight = _.clamp(newHeight, minHeight, maxHeight);

    if (height && height === newVerifiedHeight) {
      return height;
    }

    const relationsWithHeightRecalculated = recalculateRowsHeight(
      relationToResizeHeight.get('id'),
      layoutRelations,
      height - newVerifiedHeight,
    );
    toggleLayoutRelations(relationsWithHeightRecalculated);

    return newVerifiedHeight;
  }, [minHeight, maxHeight, height, toggleLayoutRelations, relationToResizeHeight, layoutRelations]);

  const toggleRowAndNeighborsHeight = useCallback((
    newHeight: number,
    relationsToUse?: LayeredRelationsMap,
    currentLayeredRelation?: LayeredRelationsMap,
  ): void => {
    const relations = relationsToUse || currentLayeredRelation || layoutRelations;
    const minRowHeight = getMinRowHeight(relationId, relations, activeLayer);
    const newVerifiedHeight = _.clamp(newHeight, minRowHeight, Infinity);
    if (height && height === newVerifiedHeight) {
      return;
    }

    // HACK: At the moment of calculation, isAutoFitContent is not yet set to the right value in store, so we store it manually
    const processedRelations = relations.setIn(
      [relationId, 'styles', activeLayer, 'isAutoFitContent'],
      isAutoFitContent,
    );

    const relationsForUpdate = calculateDeltaAndRelations(
      relationId,
      processedRelations,
      height - newVerifiedHeight,
      activeLayer,
      layout,
    );
    if (relationsForUpdate) {
      updateRelationsSilently(relationsForUpdate, layoutId);
    }
  }, [relationId, layoutRelations, activeLayer, height, isAutoFitContent, layout],
  );

  // since we share the cell width between two cells after adding a new one,
  // it's enough to check that the cell width more or equal to a doubled minimum width
  const isAddingDisabled = cellsCount >= MAX_CELLS_COUNT || width < minWidth * 2;

  const onSelectHandler = (): void => {
    if (isSelectableDocument(document)) {
      addSelectedArtboardComponent(relation.get('id'));
    }
  };

  const id = relation?.get('id');
  const selected = selectionState.get('artboardRelationIds').has(id);
  const { container: component } = useClickOutside<HTMLDivElement>(
    Priority.SELECTION,
    {
      active: selected,
      onClickOutside: () => {
        removeSelectedArtboardComponent(relation?.get('id'));
      },
      useToolbar: true,
    },
  );

  return {
    isAutoFitContent,
    toggleAutoFitContent,

    document,
    docImageWidth,
    docImageHeight,
    documentImageSrc,

    container,
    component,

    editMode,
    enterEditMode,
    toggleEditModeOff,

    canDropHere,
    copyingEnabled,
    deletingDisabled,
    isAddingDisabled,
    isParentRelationRow,
    isSelected: selected,
    minCellHeight: minHeight,
    maxCellHeight: maxHeight,

    onAddCell,
    onCopyContent,
    onDeleteCell,
    onImageLoad,
    onSelectHandler,
    onSplitCell,
    toggleColumnWidth,
    toggleRowHeight,
    toggleRowAndNeighborsHeight,
  };
};
