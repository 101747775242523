import { Selector } from 'react-redux';
import { createSelector } from 'reselect';
import { ModalWindowsMap, State } from './models';

const modalManager = (state): State.IState => state.get('modalManager');

export const modalWindows = state => modalManager(state).get('modalWindows');
export const activeModalIds = state => modalWindows(state).keySeq();

export const modalWindowOptions = <T = any>(modalWindowId: string): Selector<State.IState, T> => createSelector<State.IState, ModalWindowsMap, T>(
  modalWindows,
  windows => windows.getIn([modalWindowId, 'options'], {}) as T,
);
