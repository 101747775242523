import { DefaultCustomStyle, StylePrefix } from 'const';
import * as Models from 'models';

interface FontFamilyStyles {
  fontFamilyStyle: string;
  fontStyleStyle?: string;
  fontWeightStyle?: string;
  characterStyleNameStyle?: string;
}

export function findStyle(
  styles: Draft.DraftInlineStyle | string[],
  stylePrefix: StylePrefix,
): string | undefined {
  return styles.find((style: string) => style.startsWith(stylePrefix));
}

export function getValueFromStyle(inlineStyle: string, stylePrefix: StylePrefix): string {
  return inlineStyle.slice(stylePrefix.length);
}

export function createInlineStyleFromValue(
  stylePrefix: StylePrefix,
  value: string | number,
): string {
  return `${stylePrefix}${value}`;
}

export function getInlineStylesValue<T extends 'string' | 'number' = 'string'>(
  styles: Draft.DraftInlineStyle | string[],
  stylePrefix: StylePrefix,
  type?: T,
): (T extends 'number' ? number : string) | undefined {
  const style = findStyle(styles, stylePrefix);
  const value = style ? getValueFromStyle(style, stylePrefix) : undefined;

  if (!value) {
    return undefined;
  }

  if (type === 'number') {
    return (Number(value) || undefined) as (T extends 'number' ? number : string) | undefined;
  }

  return value as (T extends 'number' ? number : string) | undefined;
}

/**
 * Returns inline style with prefix (e.g., _font_size_20)
 * @param fontSize - font size to apply (e.g., 20)
 */
export function getFontSizeStyle(fontSize: number = DefaultCustomStyle.FONT_SIZE): string {
  return `${StylePrefix.FONT_SIZE}${fontSize}`;
}

/**
 * Returns value of applied inline style (e.g., 20)
 * @param fontSizeStyle - applied inline style (e.g., _font_size_20)
 */
export function getFontSizeFromStyle(fontSizeStyle: string): number {
  return Number(getValueFromStyle(fontSizeStyle, StylePrefix.FONT_SIZE));
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findFontSizeStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.FONT_SIZE);
}

/**
 * Returns inline style with prefix (e.g., _font_color_#FFFFFF or _font_color_SecondaryColor.White)
 * @param fontColor - font color to apply (e.g., #FFFFFF or SecondaryColor.White)
 */
export function getFontColorStyle(fontColor: string = DefaultCustomStyle.FONT_COLOR): string {
  return `${StylePrefix.FONT_COLOR}${fontColor}`;
}

export function findBrandColorByHEX(hexColor: string, colors: Models.BrandColorsList): Models.BrandColorMap | undefined {
  return colors.find(item => item.get('HEX').toUpperCase() === hexColor.toUpperCase());
}

/**
 * Returns inline style with prefix (e.g., _bullet_color_hex#FFFFFF or _bullet_color_hexSecondaryColor.White)
 * @param bulletColor - bulletColor color to apply (e.g., #FFFFFF or SecondaryColor.White)
 */
export function getBulletColorStyle(bulletColor: string = DefaultCustomStyle.FONT_COLOR): string {
  return `${StylePrefix.BULLET_COLOR}${bulletColor}`;
}

export function getPreferredColorStyle(color: string | undefined, colors: Models.BrandColorsList, isBullet = false): string {
  const brandColor = color && colors && findBrandColorByHEX(color, colors);
  const resultColor = brandColor ? brandColor.get('name') : color;

  return isBullet ? getBulletColorStyle(resultColor) : getFontColorStyle(resultColor);
}

/**
 * Returns inline style with prefix (e.g., _bulletcolor_name_SecondaryColor.White)
 * @param bulletColorName - font color to apply (e.g., SecondaryColor.White)
 */
export function getBulletColorNameStyle(bulletColorName = ''): string {
  return `${StylePrefix.BULLET_COLOR_NAME}${bulletColorName}`;
}

/**
 * Returns inline style with prefix (e.g., _bulletcolor_tint_20)
 * @param bulletColorTint - font color to apply (e.g., 20)
 */
export function getBulletColorTintStyle(bulletColorTint = 0): string {
  return `${StylePrefix.BULLET_COLOR_TINT}${bulletColorTint}`;
}

/**
 * Returns value of applied inline style (e.g., #FFFFFF or SecondaryColor.White)
 * @param bulletColorStyle - applied inline style (e.g., _bullet_color_#FFFFFF or _bullet_color_SecondaryColor.White)
 */
export function getBulletColorFromStyle(bulletColorStyle: string): string {
  if (bulletColorStyle.startsWith(StylePrefix.BULLET_COLOR)) {
    return getValueFromStyle(bulletColorStyle, StylePrefix.BULLET_COLOR);
  }

  return getValueFromStyle(bulletColorStyle, StylePrefix.BULLET_COLOR_LEGACY);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findBulletColorStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.BULLET_COLOR) || findStyle(styles, StylePrefix.BULLET_COLOR_LEGACY);
}

/**
 * Returns value of applied inline style (e.g., #FFFFFF or SecondaryColor.White)
 * @param fontColorStyle - applied inline style (e.g., _font_color_#FFFFFF or _font_color_SecondaryColor.White)
 */
export function getFontColorFromStyle(fontColorStyle: string): string {
  return getValueFromStyle(fontColorStyle, StylePrefix.FONT_COLOR);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findFontColorStyle(styles: Draft.DraftInlineStyle): Partial<Models.BrandColor> {
  return {
    HEX: getInlineStylesValue(styles, StylePrefix.FONT_COLOR) ?? '',
    name: getInlineStylesValue(styles, StylePrefix.FONT_COLOR_NAME) ?? '',
    tint: Number(getInlineStylesValue(styles, StylePrefix.FONT_COLOR_TINT) ?? ''),
  };
}

/**
 * Returns inline style with prefix (e.g., _font_family_Arial or _font_family_BaseFont)
 * @param fontFamily - font family to apply (e.g., Arial or BaseFont)
 */
export function getFontFamilyStyle(fontFamily: string = DefaultCustomStyle.FONT_FAMILY): string {
  return `${StylePrefix.FONT_FAMILY}${fontFamily}`;
}

/**
 * Returns value of applied inline style (e.g., Arial or BaseFont)
 * @param fontFamilyStyle - applied inline style (e.g., _font_family_Arial or _font_family_BaseFont)
 */
export function getFontFamilyFromStyle(fontFamilyStyle: string): string {
  return getValueFromStyle(fontFamilyStyle, StylePrefix.FONT_FAMILY);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findFontFamilyStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.FONT_FAMILY);
}

/**
 * Returns inline style with prefix (e.g., _font_style_Italic)
 * @param characterStyleName - name of character style to apply (e.g., Italic)
 */
export function getFontStyleStyle(characterStyleName: string): string {
  return `${StylePrefix.FONT_STYLE}${characterStyleName}`;
}

/**
 * Returns value of applied inline style (e.g., italic)
 * @param fontStyleStyle - applied inline style (e.g., _font_style_italic)
 */
export function getFontStyleFromStyle(fontStyleStyle: string): string {
  return getValueFromStyle(fontStyleStyle, StylePrefix.FONT_STYLE);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findFontStyleStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.FONT_STYLE);
}

/**
 * Returns inline style with prefix (e.g., _font_weight_100)
 * @param characterFontWeight - character fontWeight to apply (e.g., 100)
 */
export function getFontWeightStyle(characterFontWeight: string): string {
  return `${StylePrefix.FONT_WEIGHT}${characterFontWeight}`;
}

/**
 * Returns value of applied inline style (e.g., 100)
 * @param fontWeightStyle - applied inline style (e.g., _font_weight_100)
 */
export function getFontWeightFromStyle(fontWeightStyle: string): string {
  return getValueFromStyle(fontWeightStyle, StylePrefix.FONT_WEIGHT);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findFontWeightStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.FONT_WEIGHT);
}

/**
 * Returns inline style with prefix (e.g., _character_style_name_Regular)
 * @param characterStyleName - character style name to apply (e.g., Regular)
 */
export function getCharacterStyleNameStyle(characterStyleName: string): string {
  return `${StylePrefix.CHARACTER_STYLE_NAME}${characterStyleName}`;
}

/**
 * Returns value of applied inline style (e.g., Regular)
 * @param characterStyleNameStyle - applied inline style (e.g., _character_style_name_Regular)
 */
export function getCharacterStyleNameFromStyle(characterStyleNameStyle: string): string {
  return getValueFromStyle(characterStyleNameStyle, StylePrefix.CHARACTER_STYLE_NAME);
}

/**
 * Returns inline style if it exists, otherwise undefined
 * @param styles - collection of all inline styles
 */
export function findCharacterStyleNameStyle(styles: Draft.DraftInlineStyle): string {
  return findStyle(styles, StylePrefix.CHARACTER_STYLE_NAME);
}

export function getFontFamilyStyles(
  font: Models.BrandFontMap,
  characterStyle: Models.CharacterStyleMap | undefined,
): FontFamilyStyles {
  const fontFamilyStyle = getFontFamilyStyle(font.get('name'));
  const fontStyle = characterStyle?.get('fontStyle');
  const fontWeight = characterStyle?.get('fontWeight');
  const characterStyleName = characterStyle?.get('name');

  return {
    fontFamilyStyle,
    fontStyleStyle: fontStyle
      ? createInlineStyleFromValue(StylePrefix.FONT_STYLE, fontStyle)
      : undefined,
    fontWeightStyle: fontWeight
      ? createInlineStyleFromValue(StylePrefix.FONT_WEIGHT, fontWeight)
      : undefined,
    characterStyleNameStyle:characterStyleName
      ? createInlineStyleFromValue(StylePrefix.CHARACTER_STYLE_NAME, characterStyleName)
      : undefined,
  };
}

export function getFontFamilyStylesFromBrandStyle(
  brandFontFamily: string,
  characterStyleName: string,
  fonts: Models.BrandFontsList,
): FontFamilyStyles {
  let fontStyle;
  let fontWeight;
  let fontStyleStyle;
  let fontWeightStyle;

  const brandStyle = fonts && fonts.find(item => item.get('name') === brandFontFamily);
  const fontFamilyStyle = brandFontFamily && getFontFamilyStyle(brandFontFamily);
  const characterStyleNameStyle = characterStyleName && getCharacterStyleNameStyle(characterStyleName);

  if (brandStyle) {
    const characterStyles = brandStyle.get('characterStyles');
    if (characterStyles && characterStyles.size > 0) {
      const brandCharacterStyle = characterStyles.find(charStyle => charStyle.get('name') === characterStyleName);
      fontStyle = brandCharacterStyle && brandCharacterStyle.get('fontStyle');
      fontWeight = brandCharacterStyle && brandCharacterStyle.get('fontWeight');
    } else {
      fontStyle = brandStyle.get('fontStyle');
      fontWeight = brandStyle.get('fontWeight');
    }

    fontStyleStyle = fontStyle && getFontStyleStyle(fontStyle);
    fontWeightStyle = fontWeight && getFontWeightStyle(fontWeight);
  }

  return {
    fontFamilyStyle,
    fontStyleStyle,
    fontWeightStyle,
    characterStyleNameStyle,
  };
}
