import { useSelector } from 'react-redux';
import * as relationsSelectors from 'containers/Relations/selectors';
import * as Models from 'models';

export function useLayoutRegularFirstRelations<R extends Models.RegularRelation>(
  layout: Models.LayoutMap,
) : DeepIMap<R> {
  const relationsByRegularLayoutId = useSelector(relationsSelectors.relationsByRegularLayoutId);

  const layoutRelations = relationsByRegularLayoutId.get(layout.get('id'))!;
  const layoutRelationId = layout.get('relationId');
  const layoutRelation = layoutRelations.get(layoutRelationId) as Models.ColumnRelationMap;
  const rowRelationId = layoutRelation.get('relationIds').first<string>();
  const rowRelation = layoutRelations.get(rowRelationId) as Models.RowRelationMap;
  const relationId = rowRelation.get('relationIds').first<string>();

  return layoutRelations.get(relationId) as DeepIMap<R>;
}
