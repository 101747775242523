import Immutable from 'immutable';

import { getCallToActionDOMId } from 'components/ArtboardAssets/CallToAction';
import { getTextDOMId } from 'components/ArtboardAssets/Text';
import { Layer, MinCellHeight } from 'const';
import * as Models from 'models';
import { isCallToAction, isImage, isLayoutRendition, isTextComponent } from 'utils/entityType';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getValue, getValueIn } from 'utils/getter';

export function getMinCellHeight(
  relation: Models.LayeredRegularRelationMap | Models.LayeredRegularRelation,
  activeLayer: Layer,
  isAutoFitContent?: boolean,
  imageHeight?: number,
): number | null {

  const styles = getValueIn(relation, ['styles', activeLayer]);
  const relationId = getValue(relation, 'id');
  const documentId = getValueIn(relation, ['documentId', activeLayer]);
  const extraHeight = getExtraHeight(Immutable.fromJS(styles));

  switch (true) {
    case isCallToAction(relation as Models.RelationMap): {
      const callToActionWrapperId = getCallToActionDOMId(relationId);
      const callToActionWrapper = document.getElementById(callToActionWrapperId);

      return callToActionWrapper
        ? callToActionWrapper.clientHeight + extraHeight
        : MinCellHeight.CTA + extraHeight;
    }
    case isLayoutRendition(relation as Models.RelationMap):
    case isImage(relation as Models.RelationMap): {
      if (isAutoFitContent) {
        return MinCellHeight.IMAGE + extraHeight;
      } else if (typeof imageHeight === 'number' && Number.isSafeInteger(imageHeight)) {
        return Math.min(imageHeight, MinCellHeight.IMAGE);
      }

      return MinCellHeight.IMAGE;
    }
    case isTextComponent(relation as Models.RelationMap): {
      const textEditorWrapperId = getTextDOMId(relationId);
      const textEditorWrapper = document.getElementById(textEditorWrapperId);

      return textEditorWrapper && documentId
        ? textEditorWrapper.clientHeight + extraHeight
        : MinCellHeight.EMPTY_TEXT;
    }
    default: return null;
  }
}
