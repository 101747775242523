import classNames from 'classnames';
import React from 'react';

import Checkbox from 'components/Checkbox';
import NumericInput from 'components/NumericInput';
import { BoxPropertySide } from 'const';
import { validateEventKey } from 'containers/UndoRedoControl/utils';
import { eventEmitter, EMITTER_EVENTS } from 'utils/eventEmitter';
import { intlGet } from 'utils/intlGet';
import { useBoxPropertyControl } from './hooks';
import { BoxInputProps } from './models';
import styles from './styles.module.scss';

const SideControls = [
  BoxPropertySide.LEFT,
  BoxPropertySide.RIGHT,
  BoxPropertySide.BOTTOM,
  BoxPropertySide.TOP,
];

const EDITOR_TOOLBAR_PROPERTY = 'EditorToolbar.Property';

const TextsByBoxPropertySide: Record<BoxPropertySide, () => string> = {
  [BoxPropertySide.LEFT]: () => intlGet(EDITOR_TOOLBAR_PROPERTY, 'Left'),
  [BoxPropertySide.RIGHT]: () => intlGet(EDITOR_TOOLBAR_PROPERTY, 'Right'),
  [BoxPropertySide.BOTTOM]: () => intlGet(EDITOR_TOOLBAR_PROPERTY, 'Bottom'),
  [BoxPropertySide.TOP]: () => intlGet(EDITOR_TOOLBAR_PROPERTY, 'Top'),
};

const BoxInput: React.FunctionComponent<BoxInputProps> = (props) => {
  const { className, property, toggleProperty, min, max, step, sideControls, textsByBoxPropertySide } = props;
  const { shorthandProperty, toggleShorthandProperty } = useBoxPropertyControl(props);

  const onChange = (value: number): void => toggleProperty(property.map(() => value));
  const onPartialChange = (side: BoxPropertySide, value: number): void => toggleProperty(property.set(side, value));
  const onKeyDown = (event: any): void => {
    if ((event.ctrlKey || event.metaKey) && validateEventKey(event)) {
      eventEmitter.emit(EMITTER_EVENTS.BODY_KEYDOWN, event);
      event.preventDefault();
    }
  };

  return (
    <div className={classNames(styles.BoxInput, className)}>
      <div className={styles.inputContainer}>
        {
          shorthandProperty
            ?
            <div>
              <div className={classNames(styles.title, styles.shorthand)}>{intlGet(EDITOR_TOOLBAR_PROPERTY, 'AllSides')}</div>
              <NumericInput
                autoFocus={true}
                value={property.get(BoxPropertySide.TOP)}
                onChange={onChange}
                onKeyDown={onKeyDown}
                min={min}
                max={max}
                step={step}
              />
            </div>
            :
            <>
              {
                sideControls.map((side, index) => (
                  <div key={side}>
                    <div className={styles.title}>{textsByBoxPropertySide[side]()}</div>
                    <NumericInput
                      autoFocus={index === 0}
                      value={property.get(side)}
                      onChange={onPartialChange.bind(null, side)}
                      onKeyDown={onKeyDown}
                      min={min}
                      max={max}
                      step={step}
                    />
                  </div>
                ))
              }
            </>
        }
      </div>
      <Checkbox
        className={styles.checkbox}
        onChange={toggleShorthandProperty}
        checked={!shorthandProperty}
        label={intlGet(EDITOR_TOOLBAR_PROPERTY, 'MoreOptions')}
      />
    </div>
  );
};

BoxInput.defaultProps = {
  min: 0,
  max: Infinity,
  step: 1,
  sideControls: SideControls,
  textsByBoxPropertySide: TextsByBoxPropertySide,
};

export default BoxInput;
