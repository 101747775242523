import classNames from 'classnames';
import _ from 'lodash';
import AbbreviationsIndicator from 'modules/Abbreviations/components/AbbreviationsIndicator';
import React, { useCallback } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import createDndScrollZone from 'components/DndScrollZone';
import LoadingOverlay from 'components/LoadingOverlay';
import {
  MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER,
  MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER,
  ProcessType,
  ProjectsConfig,
} from 'const';
import * as AppSelectors from 'containers/App/selectors';
import ArtboardLayoutActions from 'containers/ArtboardLayoutActions';
import * as CommonSelectors from 'containers/Common/selectors';
import * as DocumentsSelectors from 'containers/Documents/selectors';
import * as LayoutsActions from 'containers/Layouts/actions';
import * as LayoutsSelectors from 'containers/Layouts/selectors';
import * as ProjectSelectors from 'containers/Project/selectors';
import * as RelationsSelectors from 'containers/Relations/selectors';
import * as ScreenDefinitionsSelectors from 'containers/ScreenDefinitions/selectors';
import * as Models from 'models';
import { createSmallVerticalStrength } from 'utils/dndScrollZone';
import { getLayoutSpinnerSize } from 'utils/layouts/getLayoutSpinnerSize';
import { isLayoutScrollable } from 'utils/layouts/isLayoutScrollable';
import { isRowRelation } from 'utils/relations/isRowRelation';
import { sum } from 'utils/sum';
import { toPx } from 'utils/toPx';
import Column from './components/Column';
import Toolbar from './components/Toolbar';
import { useLayout } from './hooks/useLayout';
import css from './styles.module.scss';

const DnDScrollZone = createDndScrollZone<React.HTMLAttributes<HTMLElement>>('div');

type Props = {
  artboardLayoutContainer: HTMLDivElement;
  connectDragSource: ConnectDragSource;
  disableDragAndDelete?: boolean;
  groupLayoutId: string;
  isFirst: boolean;
  isSectionActive: boolean;
  layout: Models.LayoutMap;
  layoutId: string;
  sectionDisplayName: string;
  sectionName: string;
  sectionStyles: Models.MasterScreen.SectionStylesMap;
  ssi: Models.SSIMap;
};

const ArtboardLayout: React.FunctionComponent<Props> = (originalProps: Props) => {
  const dispatch = useDispatch();

  const props = {
    ...originalProps,
    activeLayoutIds: useSelector(AppSelectors.activeLayoutIds),
    documents: useSelector(DocumentsSelectors.documents),
    flatColorsByLayoutId: useSelector(CommonSelectors.flatColorsByLayoutId),
    isGroupLayoutBeingUploaded: useSelector(LayoutsSelectors.isLayoutBeingUploaded(originalProps.groupLayoutId)),
    isLayoutBeingUploaded: useSelector(LayoutsSelectors.isLayoutBeingUploaded(originalProps.layoutId)),
    isOpenToolbar: useSelector(ProjectSelectors.isOpenToolbar),
    projectType: useSelector(ProjectSelectors.projectType),
    sectionsWidth: useSelector(ScreenDefinitionsSelectors.sectionsWidthByName),
    // override layout and relationsByRegularLayoutId to overcome zombie child issue [DCC-8828]
    // https://kaihao.dev/posts/Stale-props-and-zombie-children-in-Redux
    layout: useSelector((state: Models.AppState.StateMap) => LayoutsSelectors.layouts(state).get(originalProps.layoutId)) as Models.LayoutMap,
    relationsByRegularLayoutId: useSelector(RelationsSelectors.relationsByRegularLayoutId),
  };

  const {
    activeLayoutIds,
    connectDragSource,
    disableDragAndDelete,
    groupLayoutId,
    isGroupLayoutBeingUploaded,
    isLayoutBeingUploaded,
    isOpenToolbar,
    isSectionActive,
    layoutId,
    projectType,
    sectionDisplayName,
    sectionName,
    sectionStyles,
    sectionsWidth,
    ssi,
  } = props;

  const {
    columnsWidth,
    container,
    editMode,
    enableEditMode,
    getMinColumnWidth,
    isEmpty,
    isExpanded,
    isReusableLayout,
    layout,
    layoutDocumentName,
    layoutDocumentNumber,
    layoutRelations,
    onResize,
    styles,
    stylesSetters,
    stylesCSS,
    toggleColumnsWidth,
    toggleExpandedMode,
    toggleLayoutRelations,
    setRowHeightSilently,
  } = useLayout(props);

  const addLayoutColumn = useCallback((): void => {
    dispatch(LayoutsActions.addLayoutColumn(layout));
  }, [dispatch, layout]);

  const addLayoutRow = useCallback((): void => {
    dispatch(LayoutsActions.addLayoutRow(layout));
  }, [dispatch, layout]);

  const deleteLayoutColumn = useCallback((position: number): void => {
    dispatch(LayoutsActions.deleteLayoutColumn(layout, position));
  }, [dispatch, layout]);

  const { allowScrollableLayout } = ProjectsConfig[projectType];
  const height = layout.getIn(['styles', 'height']);

  const layoutRelation = layoutRelations.get(layout.get('relationId')) as Models.ColumnRelationMap;
  const layoutRelationChildIds = layoutRelation.get('relationIds');
  const firstLayoutChildRelation = layoutRelations.get(layoutRelationChildIds.first<string>()) as Models.RowRelationMap | Models.RegularRelationMap;
  const columnsCount = layoutRelationChildIds.size === 1 && isRowRelation(firstLayoutChildRelation)
    ? firstLayoutChildRelation.get('relationIds').size
    : 1;
  const stretchable = !layoutRelation.getIn(['styles', 'rowsHeight']).every(Boolean);

  const isAddingDisabled = React.useMemo(
    () => {
      // TODO: Delete if not needed
      // const sectionWidth = sectionsWidth.get(sectionName);
      // const { layout: newLayout, relations: newRelations } = addColumnToLayout(layout, layoutRelations, sectionWidth);
      // const cellWidthByRelationId = getCellWidthByRelationId(newLayout, newRelations, sectionWidth);

      // eslint-disable-next-line max-len
      // return _.some(cellWidthByRelationId, (width, relId) => width < getMinCellWidth(layoutRelations.get(relId) as Models.LayeredRegularRelationMap));

      return false;
    },
    [
      layout,
      layoutRelations,
      sectionName,
      sectionsWidth,
    ],
  );

  const isScrollable =
    isLayoutScrollable(layout) &&
    allowScrollableLayout &&
    !groupLayoutId &&
    !isExpanded;

  const isChildLayout = groupLayoutId && groupLayoutId !== layoutId;
  const clientHeight = container.current && container.current.getBoundingClientRect().height || MIN_HEIGHT_FOR_SM_LAYOUT_SPINNER;

  return (
    <>
      <DnDScrollZone
        verticalStrength={createSmallVerticalStrength}
        className={classNames(css.ArtboardLayout, {
          [css.scrollable]: isScrollable,
          [css.stretchable]: stretchable,
          [css.insideOfSSI]: !!ssi,
        })}
        ref={container}
        style={{ maxHeight: (isScrollable && !ssi) ? toPx(height) : 'none' }}
        data-document-number={layoutDocumentNumber}
      >
        <LoadingOverlay
          isLocked={!isChildLayout && isLayoutBeingUploaded}
          activeProcessType={clientHeight <= MIN_HEIGHT_FOR_MD_LAYOUT_SPINNER
            ? ProcessType.SAVE_LAYOUT_WITHOUT_MESSAGE
            : ProcessType.SAVE_REUSABLE_LAYOUT
          }
          spinnerSize={getLayoutSpinnerSize(clientHeight)}
        />
        <div
          className={css.columnsContainer}
          style={stylesCSS}
        >
          <Column
            activeLayoutIds={activeLayoutIds}
            columnsCount={columnsCount}
            disableCellWidthEditing={true}
            editMode={editMode}
            height={null}
            isLayoutEmpty={isEmpty}
            isOpenToolbar={isOpenToolbar}
            isReusableLayout={isReusableLayout}
            isSectionActive={isSectionActive}
            layout={layout}
            layoutId={layoutId}
            layoutRelations={layoutRelations}
            level={0}
            relation={layoutRelation}
            sectionStyles={sectionStyles}
            setRowHeightSilently={setRowHeightSilently}
            showColumnResizer={false}
            ssi={ssi}
            toggleExpandedMode={toggleExpandedMode}
            toggleLayoutRelations={toggleLayoutRelations}
            width={sum(columnsWidth)}
          />
        </div>
      </DnDScrollZone>
      {
        !ssi && editMode &&
        <Toolbar
          styles={styles}
          stylesSetters={stylesSetters}
          columnsWidth={columnsWidth}
          getMinColumnWidth={getMinColumnWidth}
          isAddingDisabled={isAddingDisabled}
          projectType={projectType}
          addLayoutColumn={addLayoutColumn}
          applyColumnsWidth={toggleColumnsWidth}
          deleteLayoutColumn={deleteLayoutColumn}
          layoutId={layoutId}
        />
      }
      {
        !ssi &&
        <ArtboardLayoutActions
          addingDisabled={isAddingDisabled}
          connectDragSource={connectDragSource}
          disableDragAndDelete={disableDragAndDelete}
          enableLayoutEditMode={enableEditMode}
          isBeingUploaded={isGroupLayoutBeingUploaded || isLayoutBeingUploaded}
          layout={layout}
          editMode={editMode}
          isChildLayout={isChildLayout}
          hint={!groupLayoutId && layoutDocumentName}
          handleDrag={onResize}
          height={_.round(height)}
          isResizeAvailable={isScrollable}
          sectionDisplayName={sectionDisplayName}
          addLayoutColumn={addLayoutColumn}
          addLayoutRow={addLayoutRow}
        />
      }
      <AbbreviationsIndicator layoutId={layoutId} />
    </>
  );
};

export default ArtboardLayout;
