import classNames from 'classnames';
import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { intlGet } from 'utils/intlGet';
import { isExistsTermOrDefinition } from '../../../utils/isExistsTermOrDefinition';
import { MIN_TERM_LENGTH } from '../constants';
import css from '../styles.module.scss';

const i18n = (name: string): string => intlGet('EditorToolbar.Abbreviation.New', name);

type Props = {
  onAdd: (definition: string) => void;
  onCancel?: () => void;
  usedTerms: string[];
};

const NewAbbreviation: React.FunctionComponent<Props> = (props) => {
  const { onAdd, onCancel, usedTerms } = props;
  const [value, setValue] = useState('');
  const [error, setError] = useState<Error | null>(null);

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
    const { target } = event;
    setValue(target.value);

    if (target.parentNode) {
      // @ts-expect-error replicatedValue is custom data-attribute
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      target.parentNode.dataset.replicatedValue = target.value;
    }
  }, []);

  const [editState, setEditState] = useState<boolean>(false);

  useEffect(()=>{
    if (editState && isExistsTermOrDefinition(value, usedTerms)) {
      setError(new Error(intlGet('EditorToolbar.Abbreviation.Error', 'AbbreviationAlreadyExists')));
    } else {
      setError(null);
    }

    if (!editState) {
      setValue('');
      setError(null);
    }
  }, [usedTerms, value, editState]);

  if (editState) {
    return (
      <div>
        <div className={css.newAbbreviation}>
          <p>{i18n('EnterAbbreviationPicture')}</p>

          <div className={css.textareaWrap}>
            <textarea
              rows={1}
              placeholder={i18n('egCV')}
              value={value}
              onInput={onChange}
            />
          </div>

          {error && (
            <div className="form-text text-danger">
              {error.message}
            </div>
          )}

          <div className={css.buttons}>
            <button
              className={css.button}
              onClick={(): void => {
                setEditState(false);
                onCancel?.();
              }}
            >
              {i18n('Button.Cancel')}
            </button>
            <button
              className={css.button}
              onClick={():void => {
                onAdd(value);
                setEditState(false);
              }}
              disabled={value.length < MIN_TERM_LENGTH || Boolean(error)}
            >
              {i18n('Button.Save')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <button
      className={classNames(css.newAbbreviationButton, css.button)}
      onClick={(): void => {
        setEditState(true);
      }}
    >
      <Icon
        color='primary'
        size="xs"
        type={IconType.ADD_SLIM}
      />
      {intlGet('EditorToolbar.Abbreviation', 'Button.Add')}
    </button>
  );
};

export default NewAbbreviation;
