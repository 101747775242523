import classNames from 'classnames';
import Immutable from 'immutable';
import React, { useRef } from 'react';
import { ConnectDragSource } from 'react-dnd';
import { useSelector } from 'react-redux';
import * as Constants from 'const';

import ArtboardLayoutActions from 'containers/ArtboardLayoutActions';
import * as documentsSelectors from 'containers/Documents/selectors';
import * as projectSelectors from 'containers/Project/selectors';
import { useBrandColorsForLayout } from 'hooks/useBrandColorsForLayout';
import { useBrandFontsForRelation } from 'hooks/useBrandFontsForRelation';
import { useLayoutRegularFirstRelations } from 'hooks/useLayoutRegularFirstRelation';
import { useUpdateCellsHeight } from 'hooks/useUpdateCellsHeight';
import * as Models from 'models';
import { selectAbbreviationsData } from '../../store/selectors';
import { AbbreviationsListRelation, AbbreviationsListRelationStylesMap } from '../../types';
import { abbreviationsListsFromContext } from '../../utils/abbreviationsListFromContext';
import List from './components/List';
import Toolbar from './components/Toolbar';
import { useAbbreviationsLayout } from './hooks/useAbbreviationsLayout';
import { useOnResize } from './hooks/useOnResize';
import { useStyles } from './hooks/useStyles';
import css from './styles.module.scss';

type Props = {
  artboardLayoutContainer: HTMLDivElement;
  connectDragSource: ConnectDragSource;
  layout: Models.LayoutMap;
};

const AbbreviationsListLayout: React.FunctionComponent<Props> = (props) => {
  const { artboardLayoutContainer, connectDragSource, layout } = props;

  const projectType = useSelector(projectSelectors.projectType);
  const projectConfig = Constants.ProjectsConfig[projectType];
  const activeLayer = useSelector(projectSelectors.activeLayer);
  const relations = useSelector(documentsSelectors.relationsOnScreens);
  const documents = useSelector(documentsSelectors.documentsByEntityType);
  const abbreviationsData = useSelector(selectAbbreviationsData);

  const relation = useLayoutRegularFirstRelations<AbbreviationsListRelation>(layout);
  const colors = useBrandColorsForLayout(layout.get('id'));
  const fonts = useBrandFontsForRelation(relation.get('id'));

  const abbreviationsListsByRelationId = abbreviationsListsFromContext(
    { activeLayer, relations, documents, abbreviations: abbreviationsData },
  );
  const abbreviations = abbreviationsListsByRelationId[relation.get('id')] || {};

  // Curently there is a lot of refactoring is needed to define ref outside of the useAbbreviationsLayout hook
  const containerSecondRef = useRef<HTMLDivElement>();

  useUpdateCellsHeight(
    relation.get('id'),
    containerSecondRef,
  );

  const [styles, stylesSetters, stylesCSS] = useStyles(
    layout.get('styles'),
    relation.getIn(['styles', activeLayer]) || Immutable.Map({}) as AbbreviationsListRelationStylesMap,
    {
      colors,
      fonts,
      images: documents.get(Constants.DocumentsKey.IMAGES),
      containerRef: containerSecondRef,
    },
    projectConfig,
  );

  const { containerRef, editMode, enableEditMode } = useAbbreviationsLayout(
    artboardLayoutContainer,
    layout,
    relation,
    activeLayer,
    styles,
  );

  const onResize = useOnResize(
    containerRef,
    styles,
    stylesSetters,
  );

  const isBlank = !abbreviations.length;

  return (
    <>
      <div
        className={classNames(css.container, {
          [css.blank]: isBlank,
          [css.scrollable]: styles.scrollable,
        })}
        style={stylesCSS}
        ref={(element: HTMLDivElement): void => {
          containerSecondRef.current = element;
          containerRef.current = element;
        }}
      >
        <List list={abbreviations} />
      </div>
      {
        editMode &&
        <Toolbar
          styles={styles}
          stylesSetters={stylesSetters}
          projectConfig={projectConfig}
          layoutId={layout.get('id')}
        />
      }
      <ArtboardLayoutActions
        connectDragSource={connectDragSource}
        layout={layout}
        height={styles.height}
        enableLayoutEditMode={enableEditMode}
        editMode={editMode}
        isResizeAvailable={styles.scrollable}
        handleDrag={onResize}
      />
    </>
  );
};

export default AbbreviationsListLayout;
