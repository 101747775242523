import { CombinedRelationStylesMap, LayoutStylesMap } from 'models';

export const getExtraHeight = (styles: CombinedRelationStylesMap | LayoutStylesMap): number => {
  if (!styles) {
    return 0;
  }

  const borderTop = styles.getIn(['border', 'width', 'top'], 0) as number;
  const borderBottom = styles.getIn(['border', 'width', 'bottom'], 0) as number;
  const paddingTop = styles.getIn(['padding', 'top'], 0) as number;
  const paddingBottom = styles.getIn(['padding', 'bottom'], 0) as number;

  return borderTop + borderBottom + paddingTop + paddingBottom;
};
